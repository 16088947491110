import React, { Component } from "react";
import { Upload, Progress, message,Button,notification} from "antd";
import { HOST } from "src/commons/api";
import "./style.less";
import videoPng from "./video.png"
import delBtn from "../upload-video-list/img/delete.png"
const STATUS = {
    WAIT:0, // 等待
    WAIT_POSTER:1, // 等待上传封面
    FINISH:2, // 上传完成
}
export default class TableComponent extends Component {

    state = {
        showMode: false, // 查看模式
        showDown: true, // 显示下载按钮
        maxNum: 10, // 图片上传最大数量
        className: null,
        loading: false, // 显示loading
       
        percent:-1,
        type:'video/*',
       
        isRemove:false,
        uploadStatus: STATUS.WAIT , // 0 
        videoUrl:'',
        videoCover:'',
    }

    //将上传的图片返回到父级页面
    triggerChange  (changedValue) {
        const { onChange } = this.props;
        if (typeof onChange === "function") {
            onChange(changedValue);
        }
     
    };

    //上传前
    handleUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 50;//是否小于50M
        if(!isLt2M){ notification.error({message:'视频大小不能超过50M'});return false;}
        if(this.state.uploadStatus == STATUS.WAIT && file.type != 'video/mp4'){
            notification.error({message:'视频仅支持上传MP4格式，若上传文件非MP4格式请转换后重新上传'}); 
            return false;
        }
        this.setState({ loading: true })
        const formData = new FormData();
        formData.append("file", file);
    };

    //上传中
    handleChange(info) {
        const {file , event} = info;
        //进度
        if (event) { // 一定要加判断，不然会报错
            let percent = Math.floor((event.loaded / event.total) * 100)
            this.setState({percent: percent})
        };
        if(file){
            if(file.status == 'done'){
                this.onUploadEnd(file.response)
                this.setState({ loading: false})
            }
        }
    }

    //上传结束的response
    onUploadEnd(res){
        let { videoUrl, videoCover,uploadStatus} = this.state;
        const { data = {} } = res;
        const { urlList = [] } = data;
        
        if(uploadStatus== STATUS.WAIT ){//更新视频
            
            videoUrl = urlList[0];
            uploadStatus = STATUS.WAIT_POSTER
        }else {//更新封面
            videoCover = urlList[0];
            uploadStatus = STATUS.FINISH
        }

        this.setState({
            uploadStatus,
            videoUrl,
            videoCover,
            percent:-1,
        }, () => {
            if(videoCover && videoUrl ){
                this.triggerChange({videoUrl,videoCover});
                this.handleRemove()
            }
           
        })
    }

    //删除图片 并回到初始状态
    handleRemove(){
        const { showMode, imgList ,list} = this.state;
        if (showMode) return;


        this.setState({
            uploadStatus:STATUS.WAIT,
            videoUrl:'',
            videoCover:'',
        }, () => {
            this.triggerChange();
        })
    };

    render() {
        
        let { uploadStatus,  loading, percent, formData, type } = this.state;
        const props = {
            showUploadList: false,
            listType: "picture",
            className: type == 'video/*' ? "video-upload":'image-upload',
            accept: uploadStatus == STATUS.WAIT ? 'video/*' :'image/*' , // image/*,video/*
            name: 'file',
            action: `${HOST}/third/Common/UploadFile`,
            method:'post',
            data: formData,
            headers: {
                authorization: 'authorization-text',
            },
            beforeUpload: file => this.handleUpload(file),
            onChange:(info)=>this.handleChange(info),
        };
        return (
            <div className="form-upload video-list">
                    {/* 视频 */}
                    { 
                        uploadStatus == STATUS.WAIT &&  <Upload {...props }>
                            { loading ? <div className='loading-percent'>{percent+'%'}</div>:
                                type == 'video/*' && 
                                <div className="ant-upload-text">
                                    <div style={{textAlign:'center',opacity: 0.32,fontSize: 18,width:'126px'}}>
                                        <img src={videoPng} opacity={1} width={20} style={{marginBottom:'5px'}}/>选择视频或拖拽视频到这里
                                    </div>
                                    <div className='ant-upload-tip'>视频素材大小不能超过50M 上传视频后需上传封面，否则无法保存</div>
                                </div>
                             
                            }
                        </Upload>

                        }
                    {/* 上传封面 */}
                    {  uploadStatus == STATUS.WAIT_POSTER &&
                        <div className='video-upload-end' style={{display: 'flex', width: '100%',alignItems: 'center'}}>
                            <Button className='iconBtn' 
                            onClick={() => {
                                this.handleRemove(0);
                                // onFresh();
                            }}>
                                <img src={delBtn} width={24}/>
                            </Button>
                            <Upload {...props}>
                            { loading ? <div className='loading-percent'>{percent+'%'}</div>:
                                <Button className='posterBtn'>上传封面</Button>
                            }
                            </Upload>
                        </div>
                        }
                        
                    <Progress 
                    strokeWidth={3} 
                    style={{
                        position: 'absolute',
                        width: 213,
                        display: percent == -1?'none':'block',
                        bottom: -8,
                        zIndex: 10,
                    }}  strokeLinecap='round' percent={percent} showInfo={false}/>
            </div>
        );
    }
};
