/**
 * 示例常量
 * @description
 * ONE:第一个
 * TWO:第二个
 */
export const EXAMPLE = {
    ONE: 1,
    TWO: 2,
};

/**
 * 平台的code
 */
export const PLATFORM_INDUSTRY_CODE = 0;

/**
 * 餐饮行业的code
 */
export const FOOD_INDUSTRY_CODE = 1;
/**
 * 商超行业的code
 */
export const SHOP_INDUSTRY_CODE = 2;

/**
 * 金额前缀
 */
export const MONEY_FRONT = '¥ '
/**
 * 表格默认展示条数
*/
export const PAGE_SIZE = 50

// 接口返回CODE
export const REQUEST_CODE = {
    LOGIN_TIMEOUT: 30000, // 登录超时
    SUCCESS: 20000, //成功
};

// 用户等级
export const USER_LEVEL = {
    1: "VIP",
    2: "1级企业客户",
    3: "2级企业客户",
    4: "3级企业客户",
    5: "黑金店主",
};
export const USER_LEVEL_KEY = {
    MIN: 1,
    MAX: 5,
};
export const USER_LEVEL_ARRAY = [
    /*{
        label: "全部",
        value: null,
    },
    {
        label: "VIP",
        value: 1,
    },
    {
        label: "1级企业客户",
        value: 2,
    },
    {
        label: "2级企业客户",
        value: 3,
    },
    {
        label: "3级企业客户",
        value: 4,
    },
    {
        label: "黑金店主",
        value: 5,
    },*/

    {
        label: "全部",
        value: null,
    },
    {
        label: "非内购会员",
        value: 0,
    },
    {
        label: "普通内购会员",
        value: 1,
    },
    {
        label: "管理员",
        value: 2,
    },
    {
        label: "超级管理员",
        value: 3,
    },
];

export const DRAW_TYPE = [
    {
        label: "全部",
        value: null,
    },
    {
        label: "手动领取",
        value: 1,
    },
    {
        label: "自动发放",
        value: 2,
    },
];

export const USER_IDENTITY_ARRAY = [
    {
        label: "全部",
        value: null,
    },
    {
        label: "非企业用户",
        value: 0,
    },
    {
        label: "普通员工",
        value: 1,
    },
    {
        label: "管理员",
        value: 2,
    },
    {
        label: "超级管理员",
        value: 3,
    },
];

// 支付方式
export const ORDER_PAY_WAY = {
    2: "微信支付",
    1: "余额支付",
    11: "微信、余额共同支付",
};

// 支付详情
export const PAY_METHOD_DETAIL = {
    WE_CHAT: "微信支付", // 微信
    INEER: "账户余额", // 余额支付
    ALI_PAY: "支付宝", //
};

// 启用/禁用
export const ENABLE_OR_DISABLE_KEY = {
    DISABLE: 0,
    ENABLE: 1,
};
export const ENABLE_OR_DISABLE = {
    0: "禁用",
    1: "启用",
};

// 素材分类
export const MATERIAL_TYPE = {
    // 0: "全部",
    1: "拉新素材",
    2: "商品海报",
    3: "宣传素材",
};
//搜索用
export const SEARCH_TYPE_APPAY = [
    {
        label: "全部",
        value: 0,
    },
    {
        label: "拉新素材",
        value: 1,
    },
    {
        label: "商品海报",
        value: 2,
    },
    {
        label: "宣传素材",
        value: 3,
    },
]
export const MATERIAL_TYPE_ARRAY = [
    // {
    //     label: "全部",
    //     value: 0,
    // },
    {
        label: "拉新素材",
        value: 1,
    },
    {
        label: "商品海报",
        value: 2,
    },
    {
        label: "宣传素材",
        value: 3,
    },
];

// 商品上架状态
export const GOODS_SHELF_STATE = {
    0: "已下架",
    1: "上架中",
};
export const GOODS_SHELF_STATE_KEY = {
    PULL_OFF_SHELVES: 0,
    PUT_ON_SALE: 1,
};
export const GOODS_SHELF_STATE_ARRAY = [
    {
        label: "全部",
        value: "",
    },
    {
        label: "已下架",
        value: 0,
    },
    {
        label: "上架中",
        value: 1,
    },
];
// 商品热销状态
export const GOODS_HOT_STATUS_KEY = {
    HOT: 1,
    NOT_HOT: 0,
};

// 商品分类级别
export const GOODS_CATEGORY_LEVEL = {
    1: "一级分类",
    2: "二级分类",
};

// 商品分类展示状态
export const GOODS_CATEGORY_SHOW_STATE = {
    0: "隐藏",
    1: "展示",
};
export const GOODS_CATEGORY_SHOW_STATE_KEY = {
    SHOW: 1,
};
export const GOODS_CATEGORY_SHOW_STATE_ARRAY = [
    {
        label: "隐藏",
        value: 0,
    },
    {
        label: "展示",
        value: 1,
    },
];

export const ACTIVITY_TOPIC_VALID_STATE_ARRAY = [
    {
        label: "生效",
        value: 0,
    },
    {
        label: "失效",
        value: 1,
    },
];

// 退款类型
export const REFUND_TYPE = {
    1: "用户申请",
    2: "售后退款",
    3: "平台退款",
};

// 退款审核状态
export const REFUND_STATE = {
    1: "未审核",
    2: "审核通过",
    3: "审核未通过",
    4: "系统自动审核通过",
};
// 退款审核状态
export const REFUND_STATE_KEY = {
    UNAUDITED: 1, // 未审核
    APPROVAL: 2, // 审核通过
    REJECT: 3, // 驳回
};
export const REFUND_STATE_ARRAY = [
    {
        label: "全部",
        value: "",
    },
    {
        label: "未审核",
        value: 1,
    },
    {
        label: "审核通过",
        value: 2,
    },
    {
        label: "审核未通过",
        value: 3,
    },
    {
        label: "系统自动审核通过",
        value: 4,
    },
];

// 售后类型
export const AFTER_SALE_SERVICE_TYPE = {
    1: "退货",
    2: "换货",
};
export const AFTER_SALE_SERVICE_TYPE_KEY = {
    RETURN: 1, // 退货
    EXCHANGE: 2 // 换货
};

// 售后状态
export const AFTER_SALE_SERVICE_STATE = {
    1: "未审核",
    2: "审核通过",
    3: "审核未通过",
    4: "完成",
};
//订单状态
export const ORDER_STATUS_OPTIONS = [
    { label: "全部", value: 1 },
    { label: "待付款", value: 10 },
    { label: "待备货", value: 11 },
    { label: "待发货", value: 2 },
    { label: "部分发货", value: 31 },
    { label: "已发货", value: 3 },
    // { label:'已签收' , value:40},
    { label: "已关闭", value: 9 },
    // { label:'已关闭' , value:91},
];
export const ORDER_STATUS = {
    10: "待支付",
    20: "待备货",
    25: "待发货",
    30: "已发货",
    40: "已签收",
    90: "已关闭",
    91: "已关闭",
};
//发货状态
export const AFTER_SALE_SERVICE_STATE_ARRAY = [
    {
        label: "全部",
        value: "",
    },
    {
        label: "未审核",
        value: 1,
    },
    {
        label: "审核通过",
        value: 2,
    },
    {
        label: "审核未通过",
        value: 3,
    },
    {
        label: "完成",
        value: 4,
    },
];
//付款状态
export const PAYMENT_STATUS_OPTIONS = [
    { label: "全部", value: "" },
    // { label: "未对账", value: 0 },
    { label: "待付款", value: 2 },
    { label: "部分付款", value: 3 },
    { label: "已付款", value: 4 },
];
export const PAYMENT_STATUS = {
    "": "全部",
    1: "审核中",
    2: "待付款",
    3: "部分付款",
    4: "已付款",
};
// 审核状态
export const AUDITING_STATE_ARRAY = [
    {
        label: "审核通过",
        value: 2,
    },
    {
        label: "审核不通过",
        value: 3,
    },
]

//上架商品筛选
export const GOODS_SCREEN_OPTIONS = [
    {
        label:'已上架未预热',
        value:1
    },{
        label:'预热中',
        value:2
    },{
        label:'售卖中',
        value:3
    },
]

export const COUPON_AMOUNT_OPTIONS = [
    {
        label:'200元优惠券',
        value:200
    },{
        label:'100元优惠券',
        value:100
    },{
        label:'50元优惠券',
        value:50
    },{
        label:'20元优惠券',
        value:20
    },{
        label:'10元优惠券',
        value:10
    },
]

export const ADD_WEIXIN_FLAG_OPTIONS = [
    {
        label:'未加微信',
        value:0
    },{
        label:'已加微信',
        value:1
    },
]

export const ADD_WEIXIN_FLAG = {
    0: "未加微信",
    1: "已加微信",
};

// 已添加微信或未添加微信
export const ADD_OR_CANCEL_WEIXINKEY = {
    CANCEL: 0,
    ADD: 1,
};

// 用户登录的身份
export const LOGIN_USER_TYPE = {
    SUPPLIER: 1
}

export default {};
