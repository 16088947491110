import React, { Component } from "react";
import { Button, Popover, Input, Row } from "antd";
import {
    WechatOutlined,
    SmileOutlined,
    CloseOutlined,
} from "@ant-design/icons";

import { findDOMNode } from "react-dom";

import { SYS_EMOJI, WX_EMOJI } from "@/constants/emoji";
import styles from "./style.less";
const { TextArea } = Input;
class EmojiTextArea extends Component {
    state = {
        sysVisible: false,
        wxVisible: false,
        value: "",
    };

    componentDidMount() {
        const { value = "" } = this.props;
        this.setState({ value});
    }

    handleClearAll(){
        this.setState({value:''},_=>{
            this.props.onChange && this.props.onChange(this.state.value);
        })
    }

    selectEmoji(val) {
        let textarea = findDOMNode(this.text);
        if (document.selection) {
            let sel = document.selection.createRange();
            sel.text = val;
            this.setState({ value: val });
            this.props.onChange && this.props.onChange(val);
        } else if (
            typeof textarea.selectionStart === "number" &&
            typeof textarea.selectionEnd === "number"
        ) {
            let startPos = textarea.selectionStart,
                endPos = textarea.selectionEnd,
                cursorPos = startPos,
                tmpStr = textarea.value;
            let v =
                tmpStr.substring(0, startPos) +
                val +
                tmpStr.substring(endPos, tmpStr.length);
            this.setState({ value: v }, () => {
                cursorPos += val.length;
                textarea.selectionStart = textarea.selectionEnd = cursorPos;
                textarea.setSelectionRange(cursorPos, cursorPos);
                textarea.focus();
            });
            this.props.onChange && this.props.onChange(v);
        } else {
            const { value } = this.state;
            this.setState({ value: value? value + val :val});
            this.props.onChange && this.props.onChange(value? value + val:val);
        }
    }

    handle({ target: { value } }) {
        this.setState({ value });
        this.props.onChange && this.props.onChange(value);
    }

    sysEmojiTable = (
        <ul className={styles.emojiTable}>
            {SYS_EMOJI.map((val, index) => (
                <li onClick={this.selectEmoji.bind(this, val)} key={index}>
                    {val}
                </li>
            ))}
        </ul>
    );

    wxEmojiTable = (
        <ul className={styles.emojiTable}>
            {WX_EMOJI.map((val, index) => (
                <li onClick={this.selectEmoji.bind(this, val)} key={index}>
                    <p
                        className={`${styles["wxEmoji"]} ${
                            styles["wxEmojiw" + (index > 69 ? 18 : 24)]
                        } ${styles["wxEmoji" + index]}`}
                    ></p>
                </li>
            ))}
        </ul>
    );

    render() {
        const {
            mode,
            style = {
                minHeight: 32,
                width: "100%",
            },
            placeholder = "请输入文本信息",
            rows = 4,
            value,
            ...p
        } = this.props;
        return (
            <div style={{ width: "100%" }}>
                <TextArea
                    style={{style,position:'relative'}}
                    placeholder={placeholder}
                    value={value}
                    rows={rows}
                    onInput={this.handle.bind(this)}
                    ref={(c) => (this.text = c)}
                    {...p}
                /><Button style={{
                    position: 'absolute',
                    right: '3px',
                    top: '3px',
                    border: 'none',
                    height: '20px',
                    padding: '0px',
                    width: '20px',
                }} type='text' onClick={this.handleClearAll.bind(this)}>×</Button>
                {mode === "emoji" && (
                    <Row
                        type="flex"
                        justify="end"
                        style={{
                            marginTop: "5px",
                            position: "absolute",
                            right: "0",
                            flexWrap:'nowrap'
                        }}
                    >
                        <Popover
                            content={
                                <div>
                                    {this.sysEmojiTable}
                                    <Row
                                        type="flex"
                                        justify="end"
                                        style={{ marginTop: "5px" }}
                                    >
                                        <Button
                                            type="primary"
                                            // onClick={() => {
                                            //     this.setState({
                                            //         sysVisible: false,
                                            //     });
                                            // }}
                                            style={{ marginRight: "10px" }}
                                        >
                                            {/* <CloseOutlined />
                                            关闭 */}
                                        </Button>
                                    </Row>
                                </div>
                            }
                            title="系统表情"
                            trigger="click"
                            arrowPointAtCenter
                            placement="bottom"
                            // visible={this.state.sysVisible}
                            
                        >
                            <Button
                                type="primary"
                                onClick={() => {
                                    this.setState({
                                        sysVisible: !this.state.sysVisible,
                                        wxVisible: false,
                                    });
                                }}
                                style={{ marginRight: "10px" }}
                            >
                                <SmileOutlined />
                                系统表情
                            </Button>
                        </Popover>
                        <Popover
                            content={
                                <div>
                                    {this.wxEmojiTable}
                                    <Row
                                        type="flex"
                                        justify="end"
                                        style={{ marginTop: "5px" }}
                                    >
                                        <Button
                                            type="primary"
                                            // onClick={() => {
                                            //     this.setState({
                                            //         wxVisible: false,
                                            //     });
                                            // }}
                                            style={{ marginRight: "10px" }}
                                        >
                                            {/* <CloseOutlined />
                                            关闭 */}
                                        </Button>
                                    </Row>
                                </div>
                            }
                            title="微信表情"
                            trigger="click"
                            arrowPointAtCenter
                            placement="bottom"
                            // visible={this.state.wxVisible}
                        >
                            <Button
                                onClick={() => {
                                    this.setState({
                                        wxVisible: !this.state.wxVisible,
                                        sysVisible: false,
                                    });
                                }}
                            >
                                <WechatOutlined />
                                微信表情
                            </Button>
                        </Popover>
                    </Row>
                )}
            </div>
        );
    }
}

export default EmojiTextArea;
