import React from 'react';
import './style.less';

// const NUM = 3
export default function (props) {
    
    // let {collapsed, children} = props
    // let buttons = children[children.length - 1]
    // let _children = children
    // if(!collapsed && _children.length > NUM+1){
    //     _children.length = NUM 
    //     _children.push(buttons) // 增加按钮组
    // }
    return (<div className="form-column-root" {...props}/>)
}
